<template>
  <div>
    <button class="bg-gray-300 mx-4 p-3" v-on:click="printToPOS">Print</button>
  </div>
</template>

<script>
import JSPM from "../../public/JSPrintManager";

export default {
  name: "Test Printer",
  props: ["title"],
  data: function () {
    return {
      print2default: true,
      printers: [],
    };
  },
  methods: {
    printToPOS: function () {
      if (this.selected_printer === "" && !this.print2default) {
        alert("You must select a printer");
        return;
      }

      var clientPrintJob = new JSPM.ClientPrintJob();

      if (this.print2default) {
        clientPrintJob.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        clientPrintJob.clientPrinter = new JSPM.InstalledPrinter(this.selected_printer);
      }

      //Set content to print...
      //Create ESP/POS commands for sample label
      var esc = "\x1B"; //ESC byte in hex notation
      var newLine = "\x0A"; //LF byte in hex notation
      var fullCut = esc + "m";

      var commands = esc + "@"; //Initializes the printer (ESC @)
      commands += esc + "!" + "\x38"; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
      commands += "CHAPMAN XPRESS"; //text to print
      commands += newLine;
      commands += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      commands += newLine + newLine;

      commands += "(SEREKUNDA)";
      commands += newLine;
      commands += "Receipt No .: 218282819828198281";
      commands += newLine + newLine;
      commands += "Meter No .: 07063358985";
      commands += newLine;

      commands += esc + "!" + "\x22";
      commands += "MUSA JALLOW";
      commands += esc + "!" + "\x00";

      commands += newLine;
      commands += "07063358985";
      commands += newLine + newLine;
      commands += "------------------ Token Number ---------------";
      commands += newLine;
      commands += esc + "1" + "\x01  ";

      commands += newLine;
      commands += "********************************************";
      commands += newLine + newLine;
      commands += esc + "!" + "\x30"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex

      commands += "0706-3358-9851-8821-9921";
      commands += newLine + newLine;
      commands += esc + "!" + "\x00";

      commands += "********************************************";
      commands += newLine + newLine;

      commands += "Amount Paid .:      GMD 10.00";
      commands += newLine;
      commands += "Fee .:      GMD 10.00";
      commands += newLine;
      commands += "Cashpower Amount .:      GMD 10.00";
      commands += newLine;
      commands += "Total Units .:     539.6kWh";
      commands += newLine;

      commands += "Payment Date .:     5th Feb, 2022, 9:24:11 pm";

      commands += newLine + newLine;
      commands += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
      commands += "Operator: Ebrima Camara";
      commands += newLine;
      commands += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      commands += "Use Energy Wisely!";
      commands += newLine;

      // commands += esc + '!' + '\x38';

      commands += newLine + newLine;
      commands += "11/03/13  19:53:17";
      commands += newLine + newLine;
      commands += newLine + newLine;

      commands += fullCut;

      clientPrintJob.printerCommands = commands;
      //Send print job to printer!
      clientPrintJob.sendToClient();
    },

    onPrinterChange: function (value) {
      this.selected_printer = value;
      console.info("Selected printer:", value);
    },
    onInit: function () {
      console.log("IN INIT", JSPM);
      var _this = this;
      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p) => {
          _this.printers = p;
          _this.$nextTick();
        });
      };
    },
    getPrinters: function () {
      return new Promise((ok, err) => {
        let printers = [];
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters()
            .then(function (myPrinters) {
              printers = myPrinters;
              console.log(printers);
              ok(printers);
            })
            .catch((e) => err(e));
        } else {
          console.warn("JSPM WS not open");
          ok(printers);
        }
      });
    },
  },
  mounted: function () {
    this.onInit();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
